<template>
  <div class="chat" :class="{ 'minimize-chat': minimize }">
    <div
      v-if="!room"
      class="
        tw-bg-gray-100
        tw-text-center
        tw-flex
        tw-flex-col
        tw-justify-center
        tw-items-center
      "
    >
      <!-- <p
      class="
        tw-text-lg tw-self-center tw-bg-white tw-px-4 tw-py-2 tw-rounded-lg
      "
    >
      Please select a chat room.
    </p> -->
      <v-progress-circular indeterminate :size="40" :width="4" color="primary">
      </v-progress-circular>
    </div>
    <div
      v-else
      class="
        tw-bg-gray-50
        tw-text-center
        tw-col-span-2
        tw-row-span-full
        tw-flex
        tw-flex-col
        tw-h-full
        tw-relative
      "
    >
      <button @click="minimize = !minimize" class="minimize-btn tw-bg-primary">
        <div v-if="minimize">
          <v-badge
            v-if="unseenMessage.main || unseenMessage.bg"
            overlap
            dot
            left
            color="pink"
          >
            <svg
              class="tw-w-6 tw-h-6 tw-text-white tw-fill-current"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
            >
              <path
                d="M447.1 256C447.1 273.7 433.7 288 416 288H109.3l105.4 105.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448s-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L109.3 224H416C433.7 224 447.1 238.3 447.1 256z"
              />
            </svg>
          </v-badge>
          <svg
            v-else
            class="tw-w-6 tw-h-6 tw-text-white tw-fill-current"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
          >
            <path
              d="M447.1 256C447.1 273.7 433.7 288 416 288H109.3l105.4 105.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448s-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L109.3 224H416C433.7 224 447.1 238.3 447.1 256z"
            />
          </svg>
        </div>
        <svg
          v-else
          class="tw-w-6 tw-h-6 tw-text-white tw-fill-current"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 448 512"
        >
          <path
            d="M438.6 278.6l-160 160C272.4 444.9 264.2 448 256 448s-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L338.8 288H32C14.33 288 .0016 273.7 .0016 256S14.33 224 32 224h306.8l-105.4-105.4c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160C451.1 245.9 451.1 266.1 438.6 278.6z"
          />
        </svg>

        <!-- <div v-else>
          <svg
            v-if="minimize"
            class="tw-w-6 tw-h-6 tw-text-white tw-fill-current"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
          >
            <path
              d="M447.1 256C447.1 273.7 433.7 288 416 288H109.3l105.4 105.4c12.5 12.5 12.5 32.75 0 45.25C208.4 444.9 200.2 448 192 448s-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L109.3 224H416C433.7 224 447.1 238.3 447.1 256z"
            />
          </svg>
          <svg
            v-else
            class="tw-w-6 tw-h-6 tw-text-white tw-fill-current"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 448 512"
          >
            <path
              d="M438.6 278.6l-160 160C272.4 444.9 264.2 448 256 448s-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L338.8 288H32C14.33 288 .0016 273.7 .0016 256S14.33 224 32 224h306.8l-105.4-105.4c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l160 160C451.1 245.9 451.1 266.1 438.6 278.6z"
            />
          </svg>
        </div> -->
      </button>
      <div class="room-detail tw-relative">
        <div
          class="
            room-header
            tw-border-b
            tw-flex
            tw-z-10
            tw-justify-between
            tw-items-center
            tw-p-4
            tw-bg-gray-100
          "
        >
          <p class="tw-font-bold tw-text-lg">
            {{ room.CreatorName }} ({{ channel }})
          </p>
          <div class="btn-group tw-flex tw-row tw-gap-2">
            <button
              v-if="isActiveParticipant"
              type="button"
              class="
                tw-inline-block tw-px-6
                disabled:tw-bg-gray-400
                tw-py-2.5
                tw-bg-primary
                tw-text-white
                tw-font-medium
                tw-text-xs
                tw-leading-tight
                tw-uppercase
                tw-rounded
                tw-shadow-md
                hover:tw-bg-primary-light hover:tw-shadow-lg
                focus:tw-bg-primary-light
                focus:tw-shadow-lg
                focus:tw-outline-none
                focus:tw-ring-0
                active:tw-bg-primary active:tw-shadow-lg
                tw-transition tw-duration-150 tw-ease-in-out
              "
              @click="onOpenAssignDialog"
            >
              Assign to
            </button>
            <button
              v-if="isActiveParticipant && channel !== 'main'"
              type="button"
              class="
                tw-inline-block
                tw-px-6
                tw-py-2.5
                tw-bg-primary
                tw-text-white
                tw-font-medium
                tw-text-xs
                tw-leading-tight
                tw-uppercase
                tw-rounded
                tw-shadow-md
                hover:tw-bg-primary-light hover:tw-shadow-lg
                focus:tw-bg-primary-light
                focus:tw-shadow-lg
                focus:tw-outline-none
                focus:tw-ring-0
                active:tw-bg-primary active:tw-shadow-lg
                tw-transition tw-duration-150 tw-ease-in-out
              "
              @click="onOpenParticipantDialog"
            >
              Paticipant
            </button>
            <!-- <button
              type="button"
              class="
                tw-inline-block
                tw-px-6
                tw-py-2.5
                tw-bg-red-500
                tw-text-white
                tw-font-medium
                tw-text-xs
                tw-leading-tight
                tw-uppercase
                tw-rounded
                tw-shadow-md
                hover:tw-bg-red-300 hover:tw-shadow-lg
                focus:tw-bg-red-300
                focus:tw-shadow-lg
                focus:tw-outline-none
                focus:tw-ring-0
                active:tw-bg-red-300 active:tw-shadow-lg
                tw-transition tw-duration-150 tw-ease-in-out
              "
              @click="onCloseModal"
            >
              Close
            </button> -->
            <div v-if="allowUpdateRoomStatus" class="room-control">
              <button
                type="button"
                v-if="
                  (room && room.Status === 'opened') ||
                    room.Status === 'abandon'
                "
                class="
                  tw-inline-block
                  tw-px-6
                  tw-py-2.5
                  tw-bg-red-500
                  tw-text-white
                  tw-font-medium
                  tw-text-xs
                  tw-leading-tight
                  tw-uppercase
                  tw-rounded
                  tw-shadow-md
                  hover:tw-bg-red-300 hover:tw-shadow-lg
                  focus:tw-bg-red-300
                  focus:tw-shadow-lg
                  focus:tw-outline-none
                  focus:tw-ring-0
                  active:tw-bg-red-300 active:tw-shadow-lg
                  tw-transition tw-duration-150 tw-ease-in-out
                "
                @click="onCloseRoom"
              >
                Close Chat
              </button>
              <button
                v-else-if="room && room.Status === 'closed'"
                type="button"
                class="
                  tw-inline-block
                  tw-px-6
                  tw-py-2.5
                  tw-bg-green-500
                  tw-text-white
                  tw-font-medium
                  tw-text-xs
                  tw-leading-tight
                  tw-uppercase
                  tw-rounded
                  tw-shadow-md
                  hover:tw-bg-green-300 hover:tw-shadow-lg
                  focus:tw-bg-green-300
                  focus:tw-shadow-lg
                  focus:tw-outline-none
                  focus:tw-ring-0
                  active:tw-bg-green-300 active:tw-shadow-lg
                  tw-transition tw-duration-150 tw-ease-in-out
                "
                @click="onOpenRoom"
              >
                Open Chat
              </button>
            </div>
            <!-- <button
            v-if="isActiveParticipant"
            type="button"
            class="tw-inline-block tw-px-2 tw-bg-transparent tw-text-primary tw-fill-current tw-font-medium tw-text-xs tw-leading-tight tw-uppercase tw-rounded hover:tw-bg-primary hover:tw-text-white hover:tw-shadow-lg focus:tw-bg-transparent focus:tw-shadow-lg focus:tw-outline-none focus:tw-ring-0 active:tw-bg-primary active:tw-shadow-lg tw-transition tw-duration-150 tw-ease-in-out"
            @click="onOpenSettingsDialog"
          >
            <svg
              class="tw-w-5 tw-h-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                d="M495.9 166.6C499.2 175.2 496.4 184.9 489.6 191.2L446.3 230.6C447.4 238.9 448 247.4 448 256C448 264.6 447.4 273.1 446.3 281.4L489.6 320.8C496.4 327.1 499.2 336.8 495.9 345.4C491.5 357.3 486.2 368.8 480.2 379.7L475.5 387.8C468.9 398.8 461.5 409.2 453.4 419.1C447.4 426.2 437.7 428.7 428.9 425.9L373.2 408.1C359.8 418.4 344.1 427 329.2 433.6L316.7 490.7C314.7 499.7 307.7 506.1 298.5 508.5C284.7 510.8 270.5 512 255.1 512C241.5 512 227.3 510.8 213.5 508.5C204.3 506.1 197.3 499.7 195.3 490.7L182.8 433.6C167 427 152.2 418.4 138.8 408.1L83.14 425.9C74.3 428.7 64.55 426.2 58.63 419.1C50.52 409.2 43.12 398.8 36.52 387.8L31.84 379.7C25.77 368.8 20.49 357.3 16.06 345.4C12.82 336.8 15.55 327.1 22.41 320.8L65.67 281.4C64.57 273.1 64 264.6 64 256C64 247.4 64.57 238.9 65.67 230.6L22.41 191.2C15.55 184.9 12.82 175.3 16.06 166.6C20.49 154.7 25.78 143.2 31.84 132.3L36.51 124.2C43.12 113.2 50.52 102.8 58.63 92.95C64.55 85.8 74.3 83.32 83.14 86.14L138.8 103.9C152.2 93.56 167 84.96 182.8 78.43L195.3 21.33C197.3 12.25 204.3 5.04 213.5 3.51C227.3 1.201 241.5 0 256 0C270.5 0 284.7 1.201 298.5 3.51C307.7 5.04 314.7 12.25 316.7 21.33L329.2 78.43C344.1 84.96 359.8 93.56 373.2 103.9L428.9 86.14C437.7 83.32 447.4 85.8 453.4 92.95C461.5 102.8 468.9 113.2 475.5 124.2L480.2 132.3C486.2 143.2 491.5 154.7 495.9 166.6V166.6zM256 336C300.2 336 336 300.2 336 255.1C336 211.8 300.2 175.1 256 175.1C211.8 175.1 176 211.8 176 255.1C176 300.2 211.8 336 256 336z"
              />
            </svg>
          </button> -->
          </div>
        </div>
        <div
          class="
            tw-absolute
            -tw-bottom-20
            tw-left-1/2 tw-transform
            -tw-translate-x-1/2
          "
        >
          <svg
            v-if="loading"
            role="status"
            class="
              tw-mt-4
              tw-w-8
              tw-self-center
              tw-h-8
              tw-mr-2
              tw-text-gray-200
              tw-animate-spin
              tw-dark:text-gray-600
              tw-fill-primary
            "
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            ></path>
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            ></path>
          </svg>
        </div>
      </div>
      <!-- <div
      class="channel-selector tw-shadow-md tw-drop-shadow-sm tw-relative tw-w-full tw-h-10 tw-flex tw-flex-row tw-rounded-full tw-overflow-hidden"
    >
      <button
        @click="setChannel('main')"
        :class="`tw-w-1/2 hover:tw-bg-slate-400  ${
          channel === 'main'
            ? 'tw-bg-slate-400 tw-text-white'
            : 'tw-bg-slate-300'
        }`"
      >
        Main
      </button>
      <button
        @click="setChannel('background')"
        :class="`tw-w-1/2 hover:tw-bg-slate-400  ${
          channel === 'background'
            ? 'tw-bg-slate-400 tw-text-white'
            : 'tw-bg-slate-300'
        }`"
      >
        Background
      </button>
    </div> -->
      <div class="tw-w-full tw-p-4">
        <div
          class="
            channel-selector
            tw-relative
            tw-w-full
            tw-flex
            tw-flex-row
            tw-rounded-md
            tw-overflow-hidden
          "
        >
          <button
            @click="setChannel('main')"
            :class="
              `tw-w-1/2 tw-relative tw-py-1 hover:tw-bg-primary-light hover:tw-text-white  ${
                channel === 'main'
                  ? 'tw-bg-primary tw-text-white'
                  : 'tw-bg-gray-200'
              }`
            "
          >
            <p>Main</p>
            <div
              v-if="unseenMessage.main"
              class="tw-absolute tw-z-50 tw-top-1 tw-left-1"
            >
              <div
                class="
                  unread-badge
                  tw-absolute
                  tw-animate-ping
                  tw-w-2
                  tw-h-2
                  tw-bg-red-400
                  tw-rounded-full
                "
              ></div>
              <div
                class="
                  unread-badge
                  tw-absolute tw-w-2 tw-h-2 tw-bg-red-400 tw-rounded-full
                "
              ></div>
            </div>
          </button>
          <button
            @click="setChannel('background')"
            :class="
              `tw-w-1/2 tw-py-1 tw-relative hover:tw-bg-primary-light hover:tw-text-white  ${
                channel === 'background'
                  ? 'tw-bg-primary tw-text-white'
                  : 'tw-bg-gray-200'
              }`
            "
          >
            <p>Background</p>
            <div
              v-if="unseenMessage.bg"
              class="tw-absolute tw-z-50 tw-top-1 tw-left-1"
            >
              <div
                class="
                  unread-badge
                  tw-absolute
                  tw-animate-ping
                  tw-w-2
                  tw-h-2
                  tw-bg-red-400
                  tw-rounded-full
                "
              ></div>
              <div
                class="
                  unread-badge
                  tw-absolute tw-w-2 tw-h-2 tw-bg-red-400 tw-rounded-full
                "
              ></div>
            </div>
          </button>
        </div>
        <!-- <button
          @click="setChannel('background')"
          :class="`tw-w-1/2 tw-py-1 tw-relative hover:tw-bg-primary-light hover:tw-text-white  ${
            channel === 'background'
              ? 'tw-bg-primary tw-text-white'
              : 'tw-bg-gray-200'
          }`"
        >
          <p>Close Chat Room</p>
        </button> -->
      </div>
      <div
        class="
          message
          tw-p-4
          tw-pt-0
          tw-mb-2
          tw-flex-auto
          tw-flex
          tw-flex-col-reverse
          tw-gap-2
          tw-overflow-y-auto
          tw-h-full
        "
        @scroll.passive="handleScroll"
      >
        <!-- <ChatMessage
          class="tw-mt-1 tw-flex-none last:tw-mt-8"
          v-for="message in messages[channel]"
          :key="message.Attributes"
          :message="message"
          v-observe-visibility="{
            callback: (isVisible, entry) =>
              visibilityChanged(isVisible, entry, message),
            once: true,
          }"
        ></ChatMessage> -->
        <component
          v-for="message in messagesWithLog[channel]"
          :key="JSON.stringify(message)"
          :is="message.ComponentName"
          :message="message"
          v-observe-visibility="{
            callback: (isVisible, entry) =>
              visibilityChanged(isVisible, entry, message),
            once: true,
          }"
        />
      </div>
      <div
        class="
          tw-flex-none
          tw-overflow-visible
          tw-relative
          tw-my-0
          tw-bg-gray-50
          tw-h-fit
          tw-py-2
          tw-flex-col
          chat-input
          tw-gap-2 tw-border-t
        "
      >
        <div
          v-if="!isActiveParticipant"
          class="
            tw-absolute
            tw-w-full
            tw-h-full
            tw-top-0
            tw-bg-black
            tw-bg-opacity-5
            tw-flex
            tw-justify-center
            tw-items-center
          "
        >
          <p v-if="activePaticipant" class="tw-text-gray-500 tw-font-light">
            This chat room was assigned to
            <strong class="tw-font-bold">{{ activePaticipant.Name }}</strong
            >.
            <button
              v-if="canTakeOverBack"
              @click="onTakeOverBack"
              class="tw-text-primary hover:tw-underline tw-px-1"
            >
              Take over back this chat
            </button>
            <button
              v-else-if="canSelfAssign || channel === 'background'"
              @click="onJoinChatRoom"
              class="tw-text-primary hover:tw-underline"
            >
              Join this chat.
            </button>
            <button
              v-else-if="room.Status === 'abandon'"
              @click="onJoinChatRoom"
              class="tw-text-primary hover:tw-underline tw-px-1"
            >
              Join this chat.
            </button>
            <span v-else-if="inCounter">{{ counterText }}</span>
            <button
              v-else
              @click="onRequestToJoin"
              class="tw-text-primary hover:tw-underline tw-px-1"
            >
              Request to join.
            </button>
          </p>
          <div v-else class="tw-text-gray-500 tw-font-light">
            <span>There is no agent in this room, </span>
            <span>you can </span>
            <button
              @click="onJoinChatRoom"
              class="tw-text-primary hover:tw-underline"
            >
              Join this chat.
            </button>
          </div>
        </div>
        <div
          class="
            action
            tw-flex-none tw-flex tw-justify-start tw-w-full tw-px-2 tw-h-8
          "
        >
          <form ref="inputImageForm">
            <input
              ref="inputImageFile"
              type="file"
              multiple
              hidden
              @change="onFileChange"
            />
          </form>
          <div class="tw-flex tw-gap-2">
            <button @click.prevent="onUploadFile">
              <svg
                class="tw-w-6 tw-h-6 tw-text-slate-400 tw-fill-current"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <path
                  d="M447.1 32h-384C28.64 32-.0091 60.65-.0091 96v320c0 35.35 28.65 64 63.1 64h384c35.35 0 64-28.65 64-64V96C511.1 60.65 483.3 32 447.1 32zM111.1 96c26.51 0 48 21.49 48 48S138.5 192 111.1 192s-48-21.49-48-48S85.48 96 111.1 96zM446.1 407.6C443.3 412.8 437.9 416 432 416H82.01c-6.021 0-11.53-3.379-14.26-8.75c-2.73-5.367-2.215-11.81 1.334-16.68l70-96C142.1 290.4 146.9 288 152 288s9.916 2.441 12.93 6.574l32.46 44.51l93.3-139.1C293.7 194.7 298.7 192 304 192s10.35 2.672 13.31 7.125l128 192C448.6 396 448.9 402.3 446.1 407.6z"
                />
              </svg>
            </button>
            <button @click.prevent="showMessageList = !showMessageList">
              <svg
                class="tw-w-6 tw-h-6 tw-text-slate-400 tw-fill-current"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <path
                  d="M256 31.1c-141.4 0-255.1 93.12-255.1 208c0 49.62 21.35 94.98 56.97 130.7c-12.5 50.37-54.27 95.27-54.77 95.77c-2.25 2.25-2.875 5.734-1.5 8.734c1.249 3 4.021 4.766 7.271 4.766c66.25 0 115.1-31.76 140.6-51.39c32.63 12.25 69.02 19.39 107.4 19.39c141.4 0 255.1-93.13 255.1-207.1S397.4 31.1 256 31.1zM127.1 271.1c-17.75 0-32-14.25-32-31.1s14.25-32 32-32s32 14.25 32 32S145.7 271.1 127.1 271.1zM256 271.1c-17.75 0-31.1-14.25-31.1-31.1s14.25-32 31.1-32s31.1 14.25 31.1 32S273.8 271.1 256 271.1zM383.1 271.1c-17.75 0-32-14.25-32-31.1s14.25-32 32-32s32 14.25 32 32S401.7 271.1 383.1 271.1z"
                />
              </svg>
            </button>
          </div>
          <emoji-picker @emoji="append" :search="search">
            <button
              class="emoji-invoker"
              slot="emoji-invoker"
              slot-scope="{ events: { click: clickEvent } }"
              @click.stop="clickEvent"
            >
              <svg
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 fill-current text-grey"
              >
                <path d="M0 0h24v24H0z" fill="none" />
                <path
                  d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm3.5-9c.83 0 1.5-.67 1.5-1.5S16.33 8 15.5 8 14 8.67 14 9.5s.67 1.5 1.5 1.5zm-7 0c.83 0 1.5-.67 1.5-1.5S9.33 8 8.5 8 7 8.67 7 9.5 7.67 11 8.5 11zm3.5 6.5c2.33 0 4.31-1.46 5.11-3.5H6.89c.8 2.04 2.78 3.5 5.11 3.5z"
                />
              </svg>
            </button>
            <div slot="emoji-picker" slot-scope="{ emojis, insert, display }">
              <div
                class="emoji-picker"
                :style="{ top: display.y + 'px', left: display.x + 'px' }"
              >
                <div class="emoji-picker__search">
                  <input type="text" v-model="search" v-focus />
                </div>
                <div>
                  <div v-for="(emojiGroup, category) in emojis" :key="category">
                    <h5>{{ category }}</h5>
                    <div class="emojis">
                      <span
                        v-for="(emoji, emojiName) in emojiGroup"
                        :key="emojiName"
                        @click="insert(emoji)"
                        :title="emojiName"
                        >{{ emoji }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </emoji-picker>
        </div>
        <div
          v-if="preview.length > 0"
          ref="preview"
          class="
            preview
            tw-px-2
            tw-h-12
            tw-w-full
            tw-flex
            tw-flex-row
            tw-overflow-auto
            tw-gap-2
          "
        >
          <img
            v-for="(url, index) in preview"
            :key="index"
            :src="url"
            alt="preview img"
          />
        </div>
        <!-- <input
        class="
          tw-bg-transparent tw-w-full tw-flex
          focus:tw-outline-none
          tw-h-auto tw-px-2 tw-resize-none
          active:tw-border-none active:tw-outline-none
        "
        type="text"
        v-model.trim="input"
        @keyup.enter="onSendMessage"
      /> -->
        <textarea
          class="
            tw-bg-transparent tw-w-full tw-flex
            focus:tw-outline-none
            tw-h-auto tw-px-2 tw-resize-none
            active:tw-border-none active:tw-outline-none
          "
          v-model.trim="input"
          @keydown.enter.exact.prevent="onSendMessage"
        ></textarea>
        <div class="tw-flex-none tw-flex tw-justify-end tw-px-2 tw-gap-2">
          <p class="tw-text-slate-400">Press enter to send message</p>
          <button
            class="tw-right-2 tw-flex-none tw-bottom-2 tw-w-fit"
            @click="onSendMessage"
          >
            <svg
              class="
                tw-w-6 tw-h-6 tw-text-slate-400
                hover:tw-text-slate-500
                tw-fill-current
              "
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
              <path
                d="M511.6 36.86l-64 415.1c-1.5 9.734-7.375 18.22-15.97 23.05c-4.844 2.719-10.27 4.097-15.68 4.097c-4.188 0-8.319-.8154-12.29-2.472l-122.6-51.1l-50.86 76.29C226.3 508.5 219.8 512 212.8 512C201.3 512 192 502.7 192 491.2v-96.18c0-7.115 2.372-14.03 6.742-19.64L416 96l-293.7 264.3L19.69 317.5C8.438 312.8 .8125 302.2 .0625 289.1s5.469-23.72 16.06-29.77l448-255.1c10.69-6.109 23.88-5.547 34 1.406S513.5 24.72 511.6 36.86z"
              />
            </svg>
          </button>
        </div>
        <div class="tw-left-2 tw--top-5 tw-absolute tw-text-xs">
          {{ typing | typingText }}
          <!-- Test -->
        </div>
        <div
          v-show="showMessageList"
          class="
            complete-message
            tw-p-2
            tw-mt-2
            tw-max-h-32
            tw-overflow-auto
            tw-w-full
            tw-flex
            tw-flex-wrap
            tw-gap-2
          "
        >
          <button
            class="
              tw-text-sm
              tw-text-left
              tw-bg-slate-200
              tw-py-1
              tw-px-2
              tw-border
              tw-rounded-md
              hover:tw-bg-gray-100
            "
            @click="
              () => {
                sendTextMessage(message);
                showMessageList = false;
              }
            "
            v-for="message in completeMessages"
            :key="message"
          >
            {{ message }}
          </button>
        </div>
      </div>
      <AssignAgentDialog v-model="showAssignDialog" />
      <ParticipantDialog v-model="showParticipantDialog" />
      <!-- <RoomSettingsDialog ref="roomSettingsDialog" /> -->
    </div>
    <NotificationCenter class="tw-fixed tw-bottom-2 tw-right-2" />
    <ModalDialog v-model="requestToJoinRemaining" title="Remaining">
      <div
        class="tw-flex tw-flex-col tw-justify-center tw-items-center tw-p-4 tw-space-y-4"
      >
        <div
          class="tw-text-4xl tw-text-blue-500 tw-w-40 tw-h-40 tw-border-blue-500 tw-text-center tw-self-center tw-border-2 tw-rounded-full tw-flex tw-justify-center tw-items-center"
        >
          <span>{{ counterText }}</span>
        </div>
        <footer class="tw-flex tw-space-x-4">
          <button
            :disabled="!canSelfAssign"
            @click="onJoinChatRoom"
            class="tw-border tw-px-2 tw-py-1 tw-border-green-500 tw-bg-green-500 tw-text-white tw-rounded-md hover:tw-bg-green-600 disabled:tw-opacity-50 disabled:tw-cursor-not-allowed"
          >
            Join this chat
          </button>
        </footer>
      </div>
    </ModalDialog>
  </div>
</template>

<script>
import Vue from "vue";
import { ObserveVisibility } from "vue-observe-visibility";
Vue.directive("observe-visibility", ObserveVisibility);

import EmojiPicker from "vue-emoji-picker";

import { debounce } from "debounce";

import { mapGetters } from "vuex";
import dayjs from "dayjs";

export default {
  name: "ChatRoom",
  props: {
    selectedRoomID: String,
    order: Object,
  },
  components: {
    ChatMessage: () => import("./ChatMessage.vue"),
    LogMessage: () => import("./LogMessage.vue"),
    AssignAgentDialog: () => import("./AssignAgentDialog.vue"),
    ParticipantDialog: () => import("./ParticipantDialog.vue"),
    NotificationCenter: () => import("./notification/NotificationCenter.vue"),
    ModalDialog: () => import("./ModalDialog.vue"),
    // RoomSettingsDialog: () => import("./RoomSettingsDialog.vue"),
    EmojiPicker,
  },
  data() {
    return {
      requestToJoinRemaining: false,
      input: "",
      search: "",
      roomSocket: null,
      consoleSocket: null,
      connected: false,
      messages: {
        main: [],
        background: [],
      },
      nextToken: {
        main: null,
        background: null,
      },
      loading: false,
      typing: {},
      showAssignDialog: false,
      showParticipantDialog: false,
      files: [],
      preview: [],
      pingInterval: {
        console: null,
        room: null,
      },
      unseenMessage: {
        main: false,
        bg: false,
      },
      minimize: false,
      showMessageList: false,
      completeMessages: [],
      canSelfAssignPolling: null,
      canSelfAssign: false,
      counters: [],
      counterInterval: null,
      counterText: "",
    };
  },
  async mounted() {
    await this.connectConsoleSocket();
    this.completeMessages = await this.$store.dispatch(
      "chat/getCompleteMessages"
    );
    await this.$store.dispatch("chat/listAgent");
  },
  computed: {
    ...mapGetters({
      rooms: "chat/rooms",
      room: "chat/room",
      channel: "chat/channel",
      // selectedRoomID: "chat/selectedRoomID",
      user: "userInfo",
      decoded: "chat/decoded",
      roomLogs: "chat/roomLogs",
    }),
    senderID() {
      // if (this.user) {
      //   return this.user.attributes.sub;
      // }
      if (this.user) {
        return this.user.userCode;
      }
      return null;
    },
    participants() {
      if (this.room) {
        if (this.channel === "main") {
          return this.room.MainChannel.Participants || [];
        }
        if (this.channel === "background") {
          return this.room.BackgroundChannel.Participants || [];
        }
      }
      return [];
    },
    activePaticipant() {
      const stack = this.room.MainChannel.ParticipantStack;
      if (!stack) {
        return null;
      }
      return this.participants.find((p) => p.ID === stack.at(-1));
    },
    isActiveParticipant() {
      if (this.channel === "background") {
        if (this.myCurrentInfo) {
          return true;
        }
      }
      if (!this.user || !this.activePaticipant) {
        return false;
      }
      return this.activePaticipant.ID === this.user.userCode;
    },
    myCurrentInfo() {
      return this.participants.find((p) => p.ID === this.user.userCode) || null;
    },
    canTakeOverBack() {
      const stack = this.room.MainChannel.ParticipantStack;
      if (!stack) {
        return false;
      }
      return stack.findIndex((s) => s === this.user.userCode) >= 0;
    },
    logs() {
      let logs = {
        main: [],
        background: [],
      };
      this.roomLogs.forEach((log) => {
        const clone = JSON.parse(JSON.stringify(log));
        clone["ComponentName"] = "LogMessage";
        if (
          log.Type === "AgentAssigned" ||
          log.Type === "RoomReopened" ||
          log.Type === "RoomCreated" ||
          log.Type === "RoomClosed"
        ) {
          if (clone.Channel === "background") {
            logs.background.push(clone);
          } else {
            logs.main.push(clone);
          }
        }
      });
      return logs;
    },
    firstMainMsgTimestamp() {
      return (
        this.messages?.main[this.messages?.main?.length - 1]?.CreatedAt || null
      );
    },
    firstBackgroundMsgTimestamp() {
      return (
        this.messages?.background[this.messages?.background?.length - 1]
          ?.CreatedAt || null
      );
    },
    messagesWithLog() {
      const msgMain = [...this.messages.main, ...this.logs.main];
      const msgBackground = [
        ...this.messages.background,
        ...this.logs.background,
      ];

      msgMain.sort(
        (a, b) => dayjs(b.CreatedAt).valueOf() - dayjs(a.CreatedAt).valueOf()
      );
      msgBackground.sort(
        (a, b) => dayjs(b.CreatedAt).valueOf() - dayjs(a.CreatedAt).valueOf()
      );

      const localMessages = {
        main: msgMain.map((msg) => {
          if (msg.ComponentName) {
            return msg;
          }
          return {
            ...msg,
            ComponentName: "ChatMessage",
          };
        }),
        background: msgBackground.map((msg) => {
          if (msg.ComponentName) {
            return msg;
          }
          return {
            ...msg,
            ComponentName: "ChatMessage",
          };
        }),
      };

      return {
        main: localMessages.main.filter(
          (msg) =>
            dayjs(msg.CreatedAt).valueOf() >=
            dayjs(this.firstMainMsgTimestamp).valueOf()
        ),
        background: localMessages.background.filter(
          (msg) =>
            dayjs(msg.CreatedAt).valueOf() >=
            dayjs(this.firstBackgroundMsgTimestamp).valueOf()
        ),
      };
    },
    allowUpdateRoomStatus() {
      const participants = this.room?.MainChannel?.ParticipantStack;
      if (!participants) {
        return false;
      }
      if (participants.length === 0) {
        return false;
      }
      const participantIdx = participants.findIndex((p) => p === this.senderID);
      return participantIdx === 0 || participantIdx === participants.length - 1;
    },
    inCounter() {
      return this.counters.find(
        (counter) => counter.id === this.selectedRoomID
      );
    },
  },
  watch: {
    consoleSocket: {
      immediate: true,
      handler: function(value) {
        if (value) {
          let me = this;
          this.consoleSocket.addEventListener("message", async (event) => {
            const message = JSON.parse(event.data);
            if (message.Type === "agent-join-accepted-notification") {
              console.log("My request has been accepted");
              // if (message.AgentID === this.senderID) {
              await this.joinChatRoom();
              me.endCounter(message.RoomID);
              if (message.RoomID === me.selectedRoomID) {
                me.requestToJoinRemaining = false;
              }
              // }
            }
            if (message.Type === "agent-join-rejected-notification") {
              console.log("My request has been rejected");
              // if (message.AgentID === this.senderID) {
              me.endCounter(message.RoomID);
              if (message.RoomID === me.selectedRoomID) {
                me.requestToJoinRemaining = false;
              }
              // }
            }
          });
        }
      },
      deep: true,
    },
    typing: {
      immediate: true,
      handler: function(value) {
        console.log("typing watcher", value);
        // const list = Object.values(value) || [];
        // console.log(list);
      },
      deep: true,
    },
    selectedRoomID: {
      immediate: true,
      handler: async function() {
        this.$store.commit("chat/setSelectedRoomID", this.selectedRoomID);
        this.$store.commit("chat/setChannel", "background");
        this.counterInterval = setInterval(() => {
          this.updateCounterText();
        }, 1000);

        await this.clearRoom();
        await this.connectWebSocket();
        this.canSelfAssign = await this.$store.dispatch(
          "chat/getCanSelfAssign",
          this.selectedRoomID
        );
        this.canSelfAssignPolling = setInterval(async () => {
          this.canSelfAssign = await this.$store.dispatch(
            "chat/getCanSelfAssign",
            this.selectedRoomID
          );
          console.log("check can self assign", this.canSelfAssign);
        }, 1000 * 60);
        this.$store.dispatch("chat/fetchEventLogs", this.selectedRoomID);
        // setInterval(() => {
        //   console.log("send ping");
        //   const TOKEN = this.$store.dispatch("chat/getJwt");
        //   const body = {
        //     action: "ping",
        //     token: TOKEN,
        //   };
        //   this.roomSocket.send(JSON.stringify(body));
        // }, 1000 * 60 * 5);
      },
    },
    input: {
      immediate: false,
      handler: debounce(
        async function(value) {
          if (value) {
            await this.$store.dispatch("chat/typing");
          }
        },
        1000,
        true
      ),
    },
    connected: {
      handler: async function(value) {
        if (!value) return;

        // const TOKEN = await this.$store.dispatch("chat/getJwt");

        // // Send ping
        // if (!this.pingInterval.room) {
        //   this.pingInterval.room = setInterval(() => {
        //     console.log("send ping");
        //     const body = {
        //       action: "ping",
        //       token: TOKEN,
        //     };
        //     this.roomSocket.send(JSON.stringify(body));
        //   }, 1000 * 60 * 5);
        // }

        // const roomID = this.selectedRoomID;
        // if (!roomID) {
        //   return;
        // }
        // const room = await this.$store.dispatch("chat/getRoom", roomID);
        // this.$store.commit("chat/setRoom", room);
        // const body = {
        //   action: "authorize",
        //   token: TOKEN,
        //   roomID: roomID,
        //   type: "Room",
        //   isCustomer: false,
        //   checkExists: true,
        //   userID: this.user.userCode,
        // };
        // await this.roomSocket.send(JSON.stringify(body));
        await this.fetchHistoryMessage();
      },
    },
    channel: {
      immediate: true,
      handler: function(value) {
        if (value === "main") return;
        const mainParticipants = this.room?.MainChannel?.Participants || [];

        const stack = this.room?.MainChannel?.ParticipantStack;
        if (!stack) {
          return null;
        }
        const me = mainParticipants.find((p) => p.ID === stack.at(-1));
        // const me = mainParticipants.find(
        //   (p) => p.ID === this. && p.IsActive
        // );
        // console.log("me", me);
        if (me) {
          const backgroundParticipants =
            this.room?.BackgroundChannel?.Participants || [];
          const bgMe = backgroundParticipants.find(
            (bp) => bp.ID === this.user.userCode
          );
          if (!bgMe) {
            // console.log("Add me to the background channel");
            try {
              //TODO assign agent
              const payload = {
                agentID: this.user.userCode,
                agentName: this.user.userFirstName,
              };
              this.$store.dispatch("chat/assignAgent", payload);
              this.$emit("input", false);
            } catch (error) {
              console.error(error);
            }
          }
        }
      },
    },
    unseenMessage: {
      immediate: true,
      handler: function(value) {
        this.$emit("update-unread-message", value.main || value.bg);
      },
      deep: true,
    },
    messages: {
      immediate: true,
      handler() {
        // console.log("messages watcher active.");
        const result = {
          main: false,
          bg: false,
        };
        const lastMainMsg = this.messages?.main[0] || null;
        const lastBgMsg = this.messages?.background[0] || null;

        if (lastMainMsg) {
          const mainSeenBy = lastMainMsg?.SeenBy;
          if (!mainSeenBy) {
            result.main = true;
          } else {
            result.main =
              mainSeenBy?.findIndex((sb) => sb === this.user.userCode) === -1;
          }
        }

        if (lastBgMsg) {
          const bgSeenBy = lastBgMsg?.SeenBy;
          if (!bgSeenBy) {
            result.bg = true;
          } else {
            result.bg =
              bgSeenBy?.findIndex((sb) => sb === this.user.userCode) === -1;
          }
        }

        // console.log(
        //   "last message watcher",
        //   lastMainMsg,
        //   lastBgMsg,
        //   result,
        //   this.user.userCode
        // );

        this.unseenMessage = result;
      },
      deep: true,
    },
  },
  methods: {
    setChannel(ch) {
      this.$store.commit("chat/setChannel", ch);
    },

    async connectConsoleSocket() {
      // const URL =
      //   "wss://6ek73q3k35.execute-api.ap-southeast-1.amazonaws.com/dev";
      const URL =
        "wss://n030cqcuu2.execute-api.ap-southeast-1.amazonaws.com/production";

      const socket = new WebSocket(URL);
      this.consoleSocket = socket;
      this.$store.commit("chat/setConsoleSocket", this.consoleSocket);
      let me = this;
      this.consoleSocket.addEventListener("open", async function(event) {
        console.log(event);
        const TOKEN = await me.$store.dispatch("chat/getJwt");
        const body = {
          action: "authorize",
          token: TOKEN,
          type: "Console",
          isCustomer: false,
          checkExists: true,
          name: me.user.userFirstName,
          userID: me.user.userCode,
          role: "Picker",
        };

        // console.log("send auth console", body);

        me.consoleSocket.send(JSON.stringify(body));

        if (!me.pingInterval.console) {
          me.pingInterval.console = setInterval(() => {
            // console.log("send ping");
            const body = {
              action: "ping",
              token: TOKEN,
            };
            me.consoleSocket.send(JSON.stringify(body));
          }, 1000 * 60 * 5);
        }

        me.connected = true;
        // console.log("Socket connected");
      });

      this.consoleSocket.addEventListener("close", function(event) {
        // console.log(event);
        me.connected = false;
        // console.log("Socket closed");
        clearInterval(me.pingInterval.console);
      });

      // this.consoleSocket.addEventListener("message", async function(event) {
      //   const message = JSON.parse(event.data);
      //   // console.log("Message from console socket", message);

      //   // let rooms = Object.assign([], me.rooms.all);
      //   // const index = rooms.findIndex((r) => r.RoomID === message.RoomID);
      //   // if (index < 0) {
      //   //   console.log("room not found");
      //   //   await me.$store.dispatch("chat/fetchRooms");
      //   // } else {
      //   //   console.log("room found", index);
      //   //   // rooms[index].UpdatedAt = message.UpdatedAt;

      //   //   const roomResponse = await me.$store.dispatch(
      //   //     "chat/getRoom",
      //   //     rooms[index].RoomID
      //   //   );
      //   //   rooms[index].UpdatedAt = roomResponse.UpdatedAt;

      //   //   const roomCardRef = me.$refs.roomCard;
      //   //   console.log("roomCardRef", roomCardRef);
      //   //   const roomCardFound = roomCardRef.find(
      //   //     (rc) => rc.room.RoomID === message.RoomID
      //   //   );
      //   //   console.log("roomCardFound", roomCardFound);
      //   //   if (roomCardFound) {
      //   //     await roomCardFound.updateLocalMessage();
      //   //   }
      //   // }
      // });
    },

    async connectWebSocket() {
      // const URL =
      //   "wss://6ek73q3k35.execute-api.ap-southeast-1.amazonaws.com/dev";
      const URL =
        "wss://n030cqcuu2.execute-api.ap-southeast-1.amazonaws.com/production";

      const socket = new WebSocket(URL);
      this.roomSocket = socket;
      this.$store.commit("chat/setRoomSocket", this.roomSocket);

      let me = this;
      this.roomSocket.addEventListener("open", async function(event) {
        // console.log(event);
        const TOKEN = await me.$store.dispatch("chat/getJwt");

        const roomID = me.selectedRoomID;
        if (!roomID) {
          return;
        }
        const room = await me.$store.dispatch("chat/getRoom", roomID);
        // me.$store.commit("chat/setRoom", room);

        //no room data >> create new room
        if (!room) {
          // console.log("room not found, create new room");
          await me.onCreateRoom();
          setTimeout(async () => {
            const room = await me.$store.dispatch("chat/getRoom", roomID);
            me.$store.commit("chat/setRoom", room);
          }, 3000);
        } else {
          me.$store.commit("chat/setRoom", room);
        }

        const body = {
          action: "authorize",
          token: TOKEN,
          roomID: roomID,
          type: "Room",
          isCustomer: false,
          checkExists: true,
          userID: me.user.userCode,
        };
        await me.roomSocket.send(JSON.stringify(body));

        // Send ping
        if (!me.pingInterval.room) {
          me.pingInterval.room = setInterval(() => {
            // console.log("send ping");
            const body = {
              action: "ping",
              token: TOKEN,
            };
            me.roomSocket.send(JSON.stringify(body));
          }, 1000 * 60 * 5);
        }
        me.connected = true;
        // console.log("Socket connected");
      });

      this.roomSocket.addEventListener("close", function(event) {
        // console.log(event);
        me.connected = false;
        // console.log("Socket closed");
        clearInterval(me.pingInterval.room);
      });

      this.roomSocket.addEventListener("message", async function(event) {
        me.$store.dispatch("chat/fetchEventLogs", me.selectedRoomID);

        const message = JSON.parse(event.data);
        // console.log("Message from room socket ", message);
        if (message.Type !== undefined) {
          console.log(message.Type);
          if (
            message.Type === "agent-assigned-notification" ||
            message.Type === "RoomClosed" ||
            message.Type === "RoomReopened"
          ) {
            const room = await me.$store.dispatch(
              "chat/getRoom",
              me.selectedRoomID
            );
            me.$store.commit("chat/setRoom", room);
            return;
          } else if (message.Type === "agent-join-accepted-notification") {
            console.log("My request has been accepted");
            if (message.AgentID === me.senderID) {
              await me.joinChatRoom();
            }
          }
        }
        if (message.Attributes === "Typing") {
          me.handlerTyping(message);
        } else {
          me.handlerMessage(message);
        }
      });
    },

    handlerMessage(message) {
      // console.log("handler message active");
      const clone = JSON.parse(JSON.stringify(this.messages));
      const foundIndex = clone[message.Channel].findIndex(
        (msg) => msg.Attributes === message.Attributes
      );
      if (foundIndex >= 0) {
        clone[message.Channel][foundIndex] = message;
      } else {
        clone[message.Channel].splice(0, 0, message);
      }
      this.messages = JSON.parse(JSON.stringify(clone));
    },
    handlerTyping(message) {
      // console.log("Typing", message);
      // if (message.CreatorID === this.user.attributes.sub) return;
      if (message.CreatorID === this.user.userCode) return;

      const now = dayjs();
      const myObj = {
        id: message.CreatorID,
        name: message.CreatorName,
        expireAt: now.add(5, "s"),
      };

      let typing = Object.assign({}, this.typing);
      typing[message.CreatorID] = myObj;

      this.typing = Object.assign({}, typing);
      setTimeout(() => {
        this.clearTyping();
      }, 5000);
      // console.log("nowww", this.typing[message.CreatorID]);
    },
    async fetchHistoryMessage() {
      const payload = {
        roomID: this.selectedRoomID,
        channel: "main",
      };
      const mainMessage = await this.$store.dispatch(
        "chat/getMessageByRoomID",
        payload
      );

      // console.log("mainMessage", mainMessage);
      this.messages["main"] = mainMessage.messages;
      this.nextToken.main = mainMessage.nextToken;

      payload.channel = "background";
      const backgroundMessage = await this.$store.dispatch(
        "chat/getMessageByRoomID",
        payload
      );
      // console.log("backgroundMessage", backgroundMessage);
      this.messages["background"] = backgroundMessage.messages;
      this.nextToken.background = backgroundMessage.nextToken;
    },

    clearTyping() {
      // console.log("clear typing");
      const keys = Object.keys(this.typing);
      let clone = this.typing;
      keys.forEach((k) => {
        if (dayjs() > clone[k].expireAt) {
          delete clone[k];
        }
      });
      this.typing = Object.assign({}, clone);
    },

    async onSendMessage() {
      if (!this.isActiveParticipant) return;
      if (this.files.length > 0) {
        this.onSendImageMessage();
      }
      if (!this.input) return;
      await this.sendTextMessage(this.input);
    },
    async sendTextMessage(msg) {
      const payload = {
        roomID: this.selectedRoomID,
        senderID: this.senderID,
        channel: this.channel,
        text: msg,
      };
      this.input = "";

      const messageResponse = await this.$store.dispatch(
        "chat/addMessage",
        payload
      );
      console.log("messageResponse", messageResponse);
      if (messageResponse) {
        this.messages[messageResponse.Channel].splice(0, 0, messageResponse);
      }
    },
    async onSendImageMessage() {
      if (!this.isActiveParticipant) return;
      try {
        const files = this.files || [];
        // console.log(files);
        let me = this;

        await Promise.all([
          // files.forEach(async (f) => {
          Array.from(files).forEach(async (f) => {
            const imageMessageResponse = await me.$store.dispatch(
              "chat/addImageMessage",
              {
                file: f,
                roomID: me.selectedRoomID,
              }
            );
            if (imageMessageResponse) {
              this.messages[imageMessageResponse.Channel].splice(
                0,
                0,
                imageMessageResponse
              );
            }
          }),
        ]);
        this.$refs.inputImageFile.values = null;
        this.$refs.inputImageForm.reset();
        this.files = [];
        this.preview = [];
      } catch (error) {
        console.error(error);
      }
    },
    async clearRoom() {
      if (this.roomSocket) {
        await this.roomSocket.close();
      }
      if (this.consoleSocket) {
        await this.consoleSocket.close();
      }
      clearInterval(this.pingInterval.room);
      clearInterval(this.pingInterval.console);
      this.pingInterval = {
        room: null,
        console: null,
      };
      this.connected = false;
      this.roomSocket = null;
      // console.log("clear room.");
      this.messages["background"] = [];
      this.messages["main"] = [];
      this.input = "";
      this.files = [];
      this.preview = [];
      this.canSelfAssign = false;
      // this.roomSocket.close();
    },
    handleScroll: debounce(function(event) {
      if (
        -event.srcElement.scrollTop + event.srcElement.clientHeight >=
        event.srcElement.scrollHeight - 10
      ) {
        this.onLoadMore();
      }
    }, 50),
    async onLoadMore() {
      // console.log("onLoadmore");
      if (!this.nextToken[this.channel]) return;
      try {
        this.loading = true;
        const payload = {
          roomID: this.selectedRoomID,
          channel: this.channel,
          nextToken: this.nextToken[this.channel],
          lastest: true,
        };
        const result = await this.$store.dispatch(
          "chat/getMessageByRoomID",
          payload
        );
        // console.log(result);
        this.messages[this.channel].push(...result.messages);
        this.nextToken[this.channel] = result.nextToken;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    onOpenAssignDialog() {
      this.showAssignDialog = true;
    },
    onOpenParticipantDialog() {
      this.showParticipantDialog = true;
    },
    onUploadFile() {
      this.$refs.inputImageFile.click();
    },
    onFileChange(e) {
      const files = e.target.files;
      // console.log(files);
      this.files = Object.values(files) || [];

      if (this.files) {
        // this.files.forEach((f) => {
        Array.from(this.files).forEach(async (f) => {
          const reader = new FileReader();

          let me = this;
          reader.addEventListener(
            "load",
            function() {
              // convert image file to base64 string
              me.preview.push(reader.result);
            },
            false
          );
          reader.readAsDataURL(f);
        });
      }
    },
    // eslint-disable-next-line no-unused-vars
    async visibilityChanged(isVisible, entry, message) {
      if (this.minimize) return;
      if (!message.Attributes) return;
      if (!this.user.userCode) return;

      this.isVisible = isVisible;
      // console.log(message);
      if (message.SeenBy !== undefined) {
        const seenBy = message.SeenBy || [];
        // console.log("seenBy", seenBy);
        if (seenBy.length > 0) {
          const index = seenBy.indexOf(this.senderID);
          // console.log("index", index, this.senderID);
          if (index >= 0) {
            return;
          }
        }
      }
      // Send seen message
      // console.log("Sending seen message");
      // this.
      this.$store.dispatch("chat/seenMessage", message);
    },
    onAddTag() {
      //
    },
    onOpenSettingsDialog() {
      const roomSettingDialogRef = this.$refs.roomSettingsDialog;
      roomSettingDialogRef.roomSettingDialog = true;
    },
    onTakeOverBack() {
      const confirm = window.confirm(
        "Are you sure you want to get this chat back?"
      );
      if (confirm) {
        // console.log("Take Over Back");
        // console.log("asign to :", this.myCurrentInfo);
        try {
          //TODO assign agent
          const payload = {
            agentID: this.user.userCode,
            agentName: this.user.userFirstName,
          };
          this.$store.dispatch("chat/assignAgent", payload);
        } catch (error) {
          console.error(error);
        }
      }
    },
    async onCloseRoom() {
      console.log("onCloseRoom");
      if (window.confirm("Do you want to close this room?")) {
        await this.$store.dispatch("chat/closeRoom", this.selectedRoomID);
      }
    },
    async onOpenRoom() {
      console.log("onOpenRoom");
      if (window.confirm("Do you want to reopen this room?")) {
        await this.$store.dispatch("chat/reOpenRoom", this.selectedRoomID);
      }
    },
    insert(emoji) {
      this.input += emoji;
    },
    append(emoji) {
      this.input += emoji;
    },
    async onJoinChatRoom() {
      const confirm = window.confirm(
        "Are you sure you want to join this chat room?"
      );
      if (confirm) {
        console.log("Join!");
        console.log("asign to :", this.user);
        try {
          //TODO assign agent
          await this.joinChatRoom();
          this.requestToJoinRemaining = false;
        } catch (error) {
          console.error(error);
        }
      }
    },
    async joinChatRoom() {
      const payload = {
        agentID: this.user.userCode,
        agentName: this.user.userFirstName,
      };
      await this.$store.dispatch("chat/assignAgent", payload);
    },
    onCloseModal() {
      this.$emit("close");
    },
    onJoinToBackgroundChannel() {
      const payload = {
        agentID: this.user.userCode,
        agentName: this.user.userFirstName,
      };
      this.$store.dispatch("chat/assignAgent", payload);
    },
    async onCreateRoom() {
      // console.log("onCreateRoom", this.createRoomFormData);
      // const { name, phone, email } = this.createRoomFormData;
      if (!this.order) {
        return;
      }
      const name = this.order.shipping.shippingFirstName;
      const phone = this.order.shipping.shippingPhone || "-";
      const email = this.order.shipping.shippingEmail || "-";
      // const name = this.order?.shipping?.shippingFirstName || "Unknown";
      // const phone = this.order?.shipping?.shippingPhone;
      // const email = this.order?.shipping?.shippingEmail;

      // let name = prompt("Please enter customer name");
      if (name == null || name == "") {
        console.log("create room cancelled");
      } else {
        console.log("confirm create room");
        // Create room and assign me

        // const uid = this.order?.ownerId;
        const uid = this.selectedRoomID;
        await this.$store.dispatch("chat/createRoom", {
          id: uid,
          name: name,
          phone: phone,
          email: email,
          agentID: this.user.userCode,
          agentName: this.user.userFirstName,
        });
      }
    },
    onRequestToJoin: debounce(
      async function() {
        if (
          window.confirm(
            "Do you want to join this chat?. You can join this chat if there is no response within 3 minutes."
          )
        ) {
          console.log("onRequestToJoin");
          try {
            await this.$store.dispatch("chat/requestToJoin", {
              AgentID: this.user.userCode,
              AgentName: this.user.userFirstName,
              Channel: this.channel,
            });
            this.counters.push({
              id: this.selectedRoomID,
              expireAt: dayjs().add(3, "minute"),
            });
            this.requestToJoinRemaining = true;
          } catch (error) {
            console.error(error);
          }
        }
      },
      1000,
      true
    ),
    updateCounterText() {
      // Remove timeout
      // this.counters = this.counters.filter(
      //   (counter) => counter.expireAt.unix() > dayjs().unix()
      // );

      if (this.inCounter === undefined) {
        this.counterText = "";
        return;
      }
      let sec = this.inCounter.expireAt.diff(dayjs(), "second");
      if (sec <= 0) {
        sec = 0;
        this.canSelfAssign = true;
      }
      this.counterText = `${Math.floor(sec / 60)}:${
        sec % 60 < 10 ? "0" + (sec % 60) : sec % 60
      }`;
    },
    endCounter(roomID) {
      this.counters = this.counters.filter((counter) => counter.id !== roomID);
    },
  },
  filters: {
    typingText(value) {
      let list = Object.values(value).filter((v) => dayjs() < v.expireAt);
      let text = "";
      if (list.length) {
        list.forEach((d, index) => {
          if (index === 0) {
            text = d.name;
          } else {
            text = text + ", " + d.name;
          }
        });
      }
      return text !== "" ? text + " typing..." : null;
    },
  },
  directives: {
    focus: {
      inserted(el) {
        el.focus();
      },
    },
  },
  async beforeDestroy() {
    await this.clearRoom();
    clearInterval(this.canSelfAssignPolling);
  },
};
</script>

<style scoped>
p {
  margin-bottom: 0;
}

.wrapper {
  position: relative;
  display: inline-block;
}

.regular-input {
  padding: 0.5rem 1rem;
  border-radius: 3px;
  border: 1px solid #ccc;
  width: 20rem;
  height: 12rem;
  outline: none;
}

.regular-input:focus {
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
}

.emoji-invoker {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s;
  padding: 0;
  background: transparent;
  border: 0;
}
.emoji-invoker:hover {
  transform: scale(1.1);
}
.emoji-invoker > svg {
  fill: #b1c6d0;
}

.emoji-picker {
  top: -330px !important;
  left: auto !important;
  right: 0 !important;
  position: absolute;
  z-index: 1;
  font-family: Montserrat;
  border: 1px solid #ccc;
  width: 15rem;
  height: 20rem;
  overflow: scroll;
  padding: 1rem;
  box-sizing: border-box;
  border-radius: 0.5rem;
  background: #fff;
  box-shadow: 1px 1px 8px #c7dbe6;
}
.emoji-picker__search {
  display: flex;
}
.emoji-picker__search > input {
  flex: 1;
  border-radius: 10rem;
  border: 1px solid #ccc;
  padding: 0.5rem 1rem;
  outline: none;
}
.emoji-picker h5 {
  margin-bottom: 0;
  color: #b1b1b1;
  text-transform: uppercase;
  font-size: 0.8rem;
  cursor: default;
}
.emoji-picker .emojis {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.emoji-picker .emojis:after {
  content: "";
  flex: auto;
}
.emoji-picker .emojis span {
  padding: 0.2rem;
  cursor: pointer;
  border-radius: 5px;
}
.emoji-picker .emojis span:hover {
  background: #ececec;
  cursor: pointer;
}
.minimize-btn {
  position: absolute;
  padding: 0.5rem;
  top: 12rem;
  left: -2.5rem;
  border-radius: 0.25rem 0 0 0.25rem;
}
.chat {
  transition: right 0.5s ease;
}
.minimize-chat {
  right: -50%;
}
</style>
